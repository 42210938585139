declare global {
    interface Window {
        dataLayer: AnalyticsEvent[]
    }
}

interface AnalyticsEvent {
    event: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

export const analyticsEvent = (event: AnalyticsEvent) => {
    if (window?.dataLayer) {
        window.dataLayer.push(event)
    }
}
