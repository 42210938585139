export async function http<T>(request: RequestInfo): Promise<T> {
    const response = await fetch(request)
    const body = await response.json()
    return body
}

export async function post<T>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any = {},
    args: RequestInit = {
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<T> {
    return await http<T>(new Request(path, args))
}
