import styled from 'styled-components'
import { Heading, Text } from '@beequip/hexagon'

export const CenteredHeading = styled(Heading)`
    text-align: center;
`

export const CenteredText = styled(Text)`
    text-align: center;
`

export const CenteredWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
