import create from 'zustand'

type CategoryFields = {
    categoryGroupId: string
    categoryId: string
}

type ObjectFromRdw = {
    brand: string
    type: string
    year: number
}

type ObjectFromLease = ObjectFromRdw &
    CategoryFields & {
        hasLeases: boolean
    }

type ObjectFromPurchaseDetails = {
    purchasePrice: number
    purchaseDate: Date
    purchaseLocation: string
}

type Valuation = {
    min: number
    max: number
}

type ObjectFromManualInput = ObjectFromRdw &
    ObjectFromPurchaseDetails &
    CategoryFields

type ObjectFromEquipmentForm = ObjectFromPurchaseDetails & CategoryFields

type State = {
    hasDataFromRdw: boolean
    hasLeases: boolean
    licensePlate: string
    brand: string
    type: string
    year: number | null
    categoryGroupId?: string
    categoryId?: string
    purchasePrice: number | null
    purchaseDate?: Date
    purchaseLocation?: string
    minimumValue: number | null
    maximumValue: number | null
    setLicensePlate: (licensePlate: string) => void
    setHasLeases: (hasLeases: boolean) => void
    setHasDataFromRdw: (hasDataFromRdw: boolean) => void
    setValuation: (data: Valuation) => void
    setObjectFromRdw: (data: ObjectFromRdw) => void
    setObjectFromLease: (data: ObjectFromLease) => void
    setObjectFromManualInput: (data: ObjectFromManualInput) => void
    setObjectFromPurchaseDetails: (data: ObjectFromPurchaseDetails) => void
    setObjectFromEquipmentForm: (data: ObjectFromEquipmentForm) => void
    reset: () => void
}

export const useStore = create<State>((set) => ({
    licensePlate: '',
    brand: '',
    type: '',
    year: null,
    purchasePrice: null,
    hasLeases: false,
    hasDataFromRdw: false,
    minimumValue: null,
    maximumValue: null,
    setLicensePlate: (licensePlate: string) =>
        set(() => ({
            licensePlate: licensePlate,
        })),
    setHasDataFromRdw: (hasDataFromRdw: boolean) =>
        set(() => ({
            hasDataFromRdw: hasDataFromRdw,
        })),
    setValuation: (data: Valuation) =>
        set(() => ({
            minimumValue: Math.round(data.min),
            maximumValue: Math.round(data.max),
        })),
    setHasLeases: (hasLeases: boolean) =>
        set(() => ({
            hasLeases: hasLeases,
        })),
    setObjectFromRdw: (data: ObjectFromRdw) =>
        set(() => ({
            brand: data.brand,
            type: data.type,
            year: data.year,
        })),
    setObjectFromLease: (data: ObjectFromLease) =>
        set(() => ({
            brand: data.brand,
            type: data.type,
            year: data.year,
            categoryGroupId: data.categoryGroupId,
            categoryId: data.categoryId,
            hasLeases: data.hasLeases,
        })),
    setObjectFromManualInput: (data: ObjectFromManualInput) =>
        set(() => ({
            brand: data.brand,
            type: data.type,
            year: data.year,
            categoryGroupId: data.categoryGroupId,
            categoryId: data.categoryId,
            purchasePrice: data.purchasePrice,
            purchaseDate: data.purchaseDate,
            purchaseLocation: data.purchaseLocation,
        })),
    setObjectFromPurchaseDetails: (data: ObjectFromPurchaseDetails) =>
        set(() => ({
            purchasePrice: data.purchasePrice,
            purchaseDate: data.purchaseDate,
            purchaseLocation: data.purchaseLocation,
        })),
    setObjectFromEquipmentForm: (data: ObjectFromEquipmentForm) =>
        set(() => ({
            categoryGroupId: data.categoryGroupId,
            categoryId: data.categoryId,
            purchasePrice: data.purchasePrice,
            purchaseDate: data.purchaseDate,
            purchaseLocation: data.purchaseLocation,
        })),
    reset: () =>
        set(() => ({
            licensePlate: '',
            brand: '',
            type: '',
            year: null,
            purchasePrice: null,
            hasLeases: false,
            hasDataFromRdw: false,
            minimumValue: null,
            maximumValue: null,
        })),
}))
